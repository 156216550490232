<template>
  <Tab :list="tabList" :current="0" :showLine="true" />
  <Statistics :list="statisticsList" />
  <div class="flex-1 fff wrap">
    <div class="title">各类产品食安合格率</div>
    <div class="list">
      <div
        class="item"
        :class="{ danger: parseInt(item.percentage) < 90 }"
        v-for="(item, index) in detail.list"
        :key="index"
      >
        <div class="label">
          <img />
          {{ item.foodName }}
        </div>
        <div class="value" :style="{ width: item.percentage }">
          {{ item.percentage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exponentTabList } from '@/utils/tab'
import { reactive, ref } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import Statistics from '@/components/statistics/index.vue'
import { getList } from '@/api/exponent'
import { getAnalysis } from '@/api/home'
import { onMounted } from 'vue'

export default {
  components: {
    Tab,
    Statistics,
  },
  setup() {
    const tabList = reactive(exponentTabList)
    const statisticsList = ref([
      {
        label: '今日检测合格率',
        value: '0',
        unit: '%',
      },
      {
        label: '今日检测总量',
        value: '0',
        unit: '批次',
      },
    ])
    onMounted(() => {
      getAnalysis().then((res) => {
        let checkCount = 0, positiveCount = 0, result = res.result.pop()

        checkCount = result.checkCount
        positiveCount = result.positiveCount
        statisticsList.value[1].value = checkCount
        // statisticsList.value[1].value = positiveCount
        statisticsList.value[0].value = (checkCount - positiveCount) / checkCount * 100 || 0
      })
    })

    let detail = reactive({
      list: [],
    })
    getList().then((res) => {
      res.result.forEach(item => {
        item.percentage = (item.sampleCount - item.positiveCount) / item.sampleCount * 100 + '%'
      })
      detail.list = res.result
    })
    return {
      tabList,
      statisticsList,
      detail,
    }
  },
}
</script>

<style lang="less" scoped>
.wrap {
  padding: 12px;
}
.title {
  font-size: 18px;
}
.item {
  margin-top: 12px;
}
.label {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 4px;
}
.value {
  line-height: 20px;
  border-radius: 10px;
  color: #fff;
  text-align: right;
  background: #3c6dff;
  padding-right: 4px;
}
.danger .value {
  background: #f00;
}
</style>
