import request from '@/utils/request'

export function getList() {
  return request({
    url: '/publicity/food/qualifiedToday',
    method: 'get'
  })
}
// 获取合格率
// export function getList() {
//   return new Promise((resolve) => {
//     const obj = {
//       list: [
//         {
//           label: '鱼肉类',
//           value: 0.99,
//           percentage: '99%',
//         },
//         {
//           label: '蔬菜类',
//           value: 0.95,
//           percentage: '95%',
//         },
//         {
//           label: '水产类',
//           value: 0.88,
//           percentage: '88%',
//         },
//       ],
//     }
//     setTimeout(() => {
//       resolve(obj)
//     }, 300)
//   })
// }
